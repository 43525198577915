import React from "react"
import "./Photos.scss"
export default function Photos() {
    let photos = [{
        imgSrc:"https://imgur.com/apIbDZP.jpg",
        caption: "Working with developers on the CSAI NIMBUS project team"
    },
    {
        imgSrc:"https://i.imgur.com/YprtGe3.jpg",
        caption: "Serving in the Finnish Defense Forces"
    },
    {
        imgSrc:"https://i.imgur.com/xLQEKNW.jpg",
        caption: "NSBE National Convention with Cal Poly NSBE"
    },        
    {
        imgSrc:"https://imgur.com/Vl4YrfQ.jpg",
        caption: "Placing second in SloHacks 2020"
    },    
    {
        imgSrc:"https://i.imgur.com/8zDLDw1.jpg",
        caption: "Playing soccer for Cypress College"
    },
    {
        imgSrc:"https://i.imgur.com/RWznAHM.jpg",
        caption: "Conducting brain-computer interface research"
    },
    {
        imgSrc:"https://i.imgur.com/fwKCL33.jpg",
        caption: "Presenting at the UC San Diego Summer Research Conference"
    }]


    return <div className="Photos">
<h2>Photos</h2>
<div className="photo-grid">
    {photos.map(photo => (<div><img src={photo.imgSrc}/> <p className="caption">{photo.caption}</p></div>))}
</div>
    </div>
}