import React, { Component } from 'react';
import MessageList from './MessageList'
import MessageForm from './MessageForm'
import './ChatBot.css';

class ChatBot extends Component {
  constructor(props) {
    super(props)
    const welcome_msg = "Hello, Chidi here and welcome to my website! CHIDai is an AI bot I created to speak on my behalf so feel free to ask questions as if you are talking to me. You can ask any questions regarding my academics, interests, projects, and engineering but for privacy reasons, nothing too personal like my date of birth or specifics about hobbies. If my bot cannot answer it, you could always send me an email! If you would like a suggested question, simply input 'Suggest'."
    this.state = {
      messages: [{me: false, author: "CHIDai", body:welcome_msg}],
      isShown: false
    }
  }

  handleNewMessage = (text) => {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({"question": text})
  };

    console.log(requestOptions);

    this.setState({
      messages: [...this.state.messages, { me: true, author: "Me", body: text }],
    })

    fetch('https://shrouded-dawn-74892.herokuapp.com/', requestOptions)
      .then(response => response.json())
      .then(answer => this.setState({
           messages: [...this.state.messages, { me: false, author: "CHIDai", body: answer["Answer"] }],
         }));
  }

  render() {
    return (
      <div className="ChatBot">
        <MessageList messages={this.state.messages} />
        <MessageForm onMessageSend={this.handleNewMessage} />
      </div>
    ) 
  }
}

export default ChatBot;