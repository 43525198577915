import React from "react"

import "./AboutMe.scss";
import Bio from "./AboutMeSections/Bio";
import Interests from "./AboutMeSections/Interests";
import Experiences from "./AboutMeSections/Experiences";
import Projects from "./AboutMeSections/Projects";
import Organizations  from "./AboutMeSections/Organizations";
import Achievements  from "./AboutMeSections/Achievements";
import Skills from "./AboutMeSections/Skills"

export default function AboutMe() {
    return <article className="AboutMe">

        <div className="content">
                    {/* <Bio/> */}
                    <Interests/>
                    <Experiences/>
                    <Projects/>
                    <Skills/>
                    <Organizations/>
                    <Achievements/>

        </div>
                </article>
} 