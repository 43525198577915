import React from "react"
export default function Projects() {
   return (
    <section className="Projects">
    <h2 id="projects"></h2>
        <h2>Projects</h2>
        <ul>
            <li><a href="https://github.com/calpoly-csai/swanton">Cal Poly Swanton Voice Assistant Project</a></li>
            <li><a href="https://www.youtube.com/watch?v=fFLzgXL9P5s">mediAInfo: Factual and Political Bias Rating of Text</a></li>
            <li>Personal Site and AI Bot*</li>
            <li><a href="https://github.com/calpoly-csai/CSAI_Voice_Assistant">CSAI Voice Assistant Project</a></li>
            <li><a href="https://github.com/chidiewenike/SMART-MiRRoR">Smart Mirror</a></li>
            <li><a href="https://youtu.be/2g0nRjGOS6Q">AI Robotic Hand</a></li>
            <li><a href="https://drive.google.com/file/d/1lWIURqYsQudutZIbT5GRtnaryurVA2Mt/view?usp=sharing">Deep Learning with Knowledge Graphs</a></li>
            <li><a href="https://devpost.com/software/melanomiq-ezxa1w">AI Skin Cancer Detector</a></li>
            <li><a href="https://www.youtube.com/watch?v=h_YPjLJTgPI">AI Driver Monitoring for Autonomous Vehicles</a></li>
            <li><a href="https://github.com/chidiewenike/Sunflower">Singing Sunflower</a></li>
            <li>Speech-To-Text Phoneme Classifier*</li>
            <li>BERT Named-Entity Recognition*</li>
        </ul>        
        * Currently formatting the GitHub
    </section>)
}