import React, {Fragment} from "react"
export default function Interests() {
   return (<section className="Interest">
    <h2>Interests</h2>
    <ul>
      <li>Artificial Intelligence/Machine Learning/Deep Learning</li>
      <li>Natural Language Processing</li>
      <li>Speech Recognition/Synthesis</li>
      <li>Knowledge Representation/Knowledge Graphs</li>
      <li>Computer Vision</li>
      <li>Predictive Modeling</li>
      <li>Data Science</li>
      <li>Robotics</li>
      <li>Automation</li>
      <li>Internet of Things</li>
      <li>Embedded Systems</li>
      <li>Digital Design</li>
    </ul>
    </section>)

}

