import React from "react"
export default function Experiences() {
   return (<section className="Experiences">
       <h2 id="experience"></h2>
    <h2>Engineering Experience</h2>
    <ul>
      <li>Microsoft (02/2021 - Present) - Software Engineer </li>
      <li>Microsoft (06/2020 - 09/2020) - Software Engineer Intern </li>
      <li>NASA Jet Propulsion Laboratory (06/2019 - 09/2019) - Flight Software Engineer Intern </li>
      <li>Cal Poly CubeSat (10/2018 - 09/2019) - Machine Learning Researcher </li>
      <li>Faraday Future (06/2018 - 09/2018) - Artificial Intelligence Engineer Intern </li>
      <li>UC San Diego Coordinated Robotics Lab (06/2017 - 08/2017) - Robotics Researcher </li>
      <li>Cal State Fullerton Brain Computer Interface Lab (06/2016 - 08/2016) - Brain Computer Interface Researcher
      </li>
    </ul>
    </section>)

}

