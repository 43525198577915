import React from "react"
export default function Experiences() {
   return (<section className="Skills">
       <h2 id="skills"></h2>
    <h2>Skills</h2>
    <ul>
      <li>Machine Learning/Deep Learning</li>
      <li>Natural Language Processing</li>
      <li>Knowledge Graphs</li>
      <li>Data Structures/Algorithms</li>
      <li>Digital Design/Microprocessors</li>
      <li>Computer Networks</li>
      <li>Systems Programming</li>
      <li>Computer Vision</li>
    </ul>

    <h2>Programming Languages</h2>
    <ul>
      <li>Python</li>
      <li>C/C++</li>
      <li>C#</li>
      <li>Java</li>
      <li>Shell</li>
      <li>Javascript</li>
      <li>HTML/CSS</li>
      <li>VHDL/Verilog</li>
    </ul>

    <h2>Frameworks</h2>
    <ul>
      <li>Tensorflow</li>
      <li>Keras</li>
      <li>PyTorch</li>
      <li>sklearn</li>
      <li>NLTK/spaCy</li>
      <li>SQL/Graql/Neo4j</li>
      <li>React</li>
      <li>ROS</li>
    </ul>

    </section>)

}

