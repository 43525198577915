import React from "react"

import "./Header.scss"

export default function Header() {
    return (
        <header className="Header">
            <div className="content">
            <img
        src="https://i.imgur.com/4ketBSK.jpg"
        className="hero-img"
      />
      <h1 className="title">Chidi Ewenike</h1>
      <h3 className="major-title">Computer Engineer</h3>
      <nav>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <ul className="contact-list">
            <li className="contact-element"><a href="mailto: chidiewenike@gmail.com" className="fa fa-envelope-o contact-link"></a></li>
            <li className="contact-element"><a className="fa fa-linkedin contact-link" href="https://www.linkedin.com/in/chidiewenike/"></a></li>
            <li className="contact-element"><a className="fa fa-github contact-link" href="https://github.com/chidiewenike"></a></li>
            <li className="contact-element"><a  className="fa fa-youtube contact-link" href="https://www.youtube.com/channel/UCx0Zcuo9jeoOLd5Qtm8Acpg/"></a></li>
        </ul>


      </nav>
      
            </div>
      
 
    </header>
    )
}