import React from "react"
export default function Organizations() {
   return (
    <section className="Organizations">
        <h2>Organizations</h2>
        <ul>
        <li>Cal Poly Computer Science and Artificial Intelligence Club - Co-Founder and Projects Officer</li>
        <li>Cal Poly National Society of Black Engineers - Club Showcase Chair & Former Executive Board Secretary</li>
        <li>Cypress College Science, Technology, Engineering, and Mathematics - Volunteer & Engineering Ambassador</li>
        <li>Cypress College Toastmasters - Co-Founder & Operations Officer</li>
        </ul>
    </section>
    
    )
}