import React, { Component } from 'react';
import Header from "./Header/Header";
import Intro from "./Intro/Intro"
import AboutMe from "./AboutMe/AboutMe";
import Resume from "./Resume/Resume";
import Photos from "./Photos/Photos";
import ChatBot from './ChatBot/ChatBot';
import Disclaimer from './Disclaimer/Disclaimer';

class App extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    return (
      <div id="app">
        <Header/>
        <Intro/>
        <ChatBot/>
        <Disclaimer/>
        <AboutMe/>
        <Resume/>
        <Photos/>
      </div>
      
    );
  }
}

export default App;