import React from "react"

import "./Intro.scss"

export default function Intro() {
    return (
        <header className="Intro">
            <div className="content">
                <h1 className="title">CHIDai</h1>
            </div>
    </header>
    )
}