import React from "react"

import "./Disclaimer.scss"

export default function Disclaimer() {
    return (
        <header className="Disclaimer">
            <div className="content">
                <body className="disclaimer">Note: The bot is a prototype that was completed 06/20/20. As time goes by and users interact with the bot, responses will improve. Inaccurate responses allow the bot to improve in the future.</body>
            </div>
    </header>
    )
}