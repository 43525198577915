import React from "react"
export default function Achievements() {
   return (
    <section className="Achievements">
        <h2>Achievements</h2>
        <ul>
            <li>Microsoft Global Hackathon World Knowledge Hack "Best User Value" Winner: mediAInfo</li>
            <li>HackNSBE Hackathon Winner</li>
            <li>SLOHacks Overall Runner-Up and Google Competition 3rd-Place Winner: MelanomIQ</li>
            <li>R&J Stollmeyer Opportunity Academic Scholarship Recipient</li>
            <li>Cal Poly Dean's List - Fall 2017, Winter 2018, Spring 2018, Fall 2019, Winter 2020</li>
            <li>Cypress College President's List - Fall 2015, Spring 2016, Spring 2017</li>
            <li>Cypress College Kathy Godshalk Engineering Award of Excellence</li>
        </ul>
    </section>
    )

}
